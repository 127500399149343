import {PaymentPurpose, PaymentStatus} from "@/constants/PaymentConstants";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

export class PaymentDTO {
    id?: number;
    createDate?: Date;
    updateDate?: Date;
    amount?: number;
    paymentPurpose?: PaymentPurpose;
    paymentStatus?: PaymentStatus;
    scheduledPaymentDate?: Date;
    actualPaymentDate?: Date | null;
    invoiceNumber?: string;
    invoiceDocument?: FileMetaDTO;

    constructor(init?: Partial<PaymentDTO>) {
        Object.assign(this, init);
    }
}
